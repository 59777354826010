body {
  margin: 0;
  padding: 0;
  font-family: "Magra", sans-serif;
}

.media-list-container {
  background-color: #EDEDDF;
  min-height: 100vh;
  padding: 2rem;
}

.content-wrapper {
  max-width: 40rem;
  margin: 0 auto;
  padding: 0;
  position: relative;
}

.page-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
}