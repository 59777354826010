.search-container {
  position: relative;
  margin-bottom: 1.5rem;
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
}

.search-input {
  width: 100%;
  padding: 0.5rem 1rem 0.5rem 2.5rem;
  border: 1px solid black;
  border-radius: 9999px;
  font-size: 1rem;
  box-sizing: border-box;
  font-family: "Magra", sans-serif;
}

.search-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #9ca3af;
}

.search-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid black;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  max-height: 15rem;
  overflow-y: auto;
  z-index: 10;
}

.search-result-item {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-bottom: 1px solid #e5e7eb;
}

.search-result-item:last-child {
  border-bottom: none;
}

.dropdown-poster {
  width: 2.5rem;
  height: 3.5rem;
  object-fit: cover;
  background-color: black;
  border-radius: 0.25rem;
  margin-right: 0.75rem;
}

.result-text {
  flex-grow: 1;
}

.added-label {
  color: #4b5563;
  font-size: 0.875rem;
  font-weight: bold;
  margin-left: 0.5rem;
  padding: 0.25rem 0.5rem;
  border: 1px solid #4b5563;
  border-radius: 0.25rem;
  background-color: #f3f4f6;
}

.add-button {
  color: #9ca3af;
  font-size: 1.25rem;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 0.5rem;
}

.add-button:hover {
  color: #4b5563;
}
