header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding: 0 1rem;
}

.logo {
  font-size: 1.5rem;
  font-family: "Magra", sans-serif;
  margin-right: auto;
}

.nav-buttons {
  display: none;
}

.desktop-nav-buttons {
  display: flex;
  gap: 0.5rem;
}

.desktop-nav-buttons button {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  background-color: white;
  border: 1px solid black;
  border-radius: 8px;
  font-size: 0.875rem;
  font-family: "Magra", sans-serif;
}

.desktop-nav-buttons button .icon {
  margin-right: 0.25rem;
}

.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.mobile-menu-dropdown {
  display: none;
  position: absolute;
  top: 3rem;
  right: 1rem;
  background-color: white;
  border: 1px solid black;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.mobile-menu-dropdown.show {
  display: block;
}

.mobile-menu-dropdown button {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 1rem;
}

.mobile-menu-dropdown button:hover {
  background-color: #f3f4f6;
}

.logout-button {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  background-color: white;
  border: 1px solid black;
  border-radius: 8px;
  font-size: 0.875rem;
  font-family: "Magra", sans-serif;
}

.logout-button .icon {
  margin-right: 0.25rem;
}

@media (max-width: 768px) {
  .logo {
    margin-right: 0;
  }

  .desktop-nav-buttons {
    display: none;
  }

  .nav-buttons {
    display: flex;
  }

  .mobile-menu-button {
    display: block;
  }

  .mobile-menu-dropdown {
    display: none;
  }

  .mobile-menu-dropdown.show {
    display: block;
  }
}
