.media-list {
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 30rem;
  position: relative;
}

.media-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 0.75rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid black;
}

.media-info {
  display: flex;
  align-items: center;
}

.media-poster {
  width: 2.5rem;
  height: 3.5rem;
  object-fit: cover;
  background-color: black;
  border-radius: 0.25rem;
  margin-right: 0.75rem;
}

.remove-button {
  color: #9ca3af;
  font-size: 1.25rem;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
}

.remove-button:hover {
  color: #4b5563;
}

.empty-message {
  text-align: center;
  color: #6b7280;
  font-size: 1rem;
  font-family: "Magra", sans-serif;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
}

.pagination .pagination-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.pagination span {
  text-align: center;
}

.pagination-button {
  padding: 0.5rem 1rem;
  background-color: white;
  border: 1px solid black;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
}

.pagination-button.disabled {
  color: #9ca3af;
  cursor: not-allowed;
}

.pagination-button:not(.disabled):hover {
  background-color: #f3f4f6;
}
