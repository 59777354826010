.filter-sort-container {
  position: relative; /* Ensure the dropdown is positioned relative to this container */
}

.filter-sort-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  margin-left: 1rem; /* Adjust as needed to position correctly within the pagination */
}

.sort-dropdown {
  position: absolute;
  bottom: 2.5rem; /* Position the dropdown above the button */
  right: 0;
  background-color: white;
  border: 1px solid black;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  z-index: 10;
  max-height: 10rem; /* Limit the height of the dropdown */
  overflow-y: auto; /* Allow scrolling if the content exceeds the max height */
}

.sort-dropdown button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  font-family: "Magra", sans-serif;
}

.sort-dropdown button:hover {
  background-color: #f3f4f6;
}

.check-mark {
  color: green;
  margin-left: auto;
}
