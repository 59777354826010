/* Centered container for the auth forms */
.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #EDEDDF;
}

/* Form container similar to the provided screenshot */
.auth-form {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.auth-logo {
  font-size: 2rem;
  font-family: "Magra", sans-serif;
  margin-bottom: 1rem;
}

.auth-form h2 {
  font-family: "Magra", sans-serif;
  margin-bottom: 1rem;
}

.auth-form input {
  width: 100%;
  padding: 0.75rem;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
}

.auth-form button {
  width: 100%;
  padding: 0.75rem;
  margin: 1rem 0;
  border: none;
  border-radius: 5px;
  background-color: #000;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  font-family: "Magra", sans-serif;
}

.auth-form button:hover {
  background-color: #333;
}

.auth-form a {
  display: block;
  margin-top: 1rem;
  color: #333;
  text-decoration: none;
}

.auth-form a:hover {
  text-decoration: underline;
}

.auth-form .auth-alternative {
  margin: 1rem 0;
  color: #666;
}

@media (max-width: 768px) {
  .auth-form {
    padding: 1rem;
  }

  .auth-logo {
    font-size: 1.5rem;
  }

  .auth-form input {
    padding: 0.5rem;
  }

  .auth-form button {
    padding: 0.5rem;
  }
}
